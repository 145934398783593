var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"tables new-updated-design"},[_c('vue-snotify'),_c('div',[_c('b-modal',{ref:"referral-modal",attrs:{"id":"modal","title":_vm.referralNote}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('p',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.modalContent))])])])])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 grid-margin stretch-card"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('View_Users.Users')))])]),_c('div',{staticClass:"card-body"},[_c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"pagination-options":{
                            enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                            prevLabel: this.$t('pagination.Previous'),
                            rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                            ofLabel: this.$t('pagination.of'),
                        },"rows":_vm.rows,"columns":_vm.columns,"sort-options":{
                            enabled: false,
                        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'name')?_c('span',[_c('span',[_vm._v(" "+_vm._s(props.row.name))])]):_vm._e(),(props.column.field == 'email')?_c('span',[_c('span',[_vm._v(" "+_vm._s(props.row.email))])]):_vm._e(),(props.column.field == 'mobile_phone')?_c('span',[_c('span',[_vm._v(" "+_vm._s(props.row.mobile_phone))])]):_vm._e()]}}])})],1)])])]),_c('div')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }