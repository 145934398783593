<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="modal" :title="referralNote" ref="referral-modal">
                <div class="row">
                    <div class="col-md-12">
                        <p class="mb-3">{{ modalContent }}</p>
                    </div>
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('View_Users.Users') }}</h4>
                    </div>
                    <div class="card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field == 'name'">
                                    <span> {{ props.row.name }}</span>
                                </span>
                                <span v-if="props.column.field == 'email'">
                                    <span> {{ props.row.email }}</span>
                                </span>
                                <span v-if="props.column.field == 'mobile_phone'">
                                    <span> {{ props.row.mobile_phone }}</span>
                                </span>
                                <!-- <span v-if="props.column.field === 'action'" class="text-nowrap">
                                   
                                    <b-button size="sm" @click="editProperty(props.row)" class="mr-1 btn-info text-white">
                                        Edit
                                    </b-button>
                                    <b-button  size="sm"
                                        @click="deleteProperty(props.row.id)" class="mr-1 btn-danger text-white">
                                        Delete
                                    </b-button>
                                </span> -->
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api'
import moment from 'moment'

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            searchTime: null,
            isDisabled: false,
            referralNote: 'Referral Note',
            modalContent: '',
            selected: true,
            unSelected: false,
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    filterable: false,
                },
                {
                    label: 'Email',
                    field: 'email',
                    sortable: false,
                },

                {
                    label: 'Phone',
                    field: "mobile_phone",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'name':
                        col.label = this.$t('View_Users.name');
                        break;
                    case 'email':
                        col.label = this.$t('View_Users.email');
                        break;
                    case 'mobile_phone':
                        col.label = this.$t('View_Users.phone');
                        break;
                    default:
                        break;
                }
            });
        },
        loadItems() {
            API.getAllusers(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        }
    },
    computed: {
        name() {
            return this.$t('View_Users.name');
        },
        email() {
            return this.$t('View_Users.email');
        },
        phone() {
            return this.$t('View_Users.phone');
        },
    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'name').label = this.name;
            this.columns.find(col => col.field === 'email').label = this.email;
            this.columns.find(col => col.field === 'mobile_phone').label = this.phone;
        }
    }
}
</script>